<template>
  <div class="list">
    <NavBar title="清单列表" />
    <div>
      <van-row class="list-header">
        <van-col span="6">房产</van-col>
        <van-col span="12">签收状态</van-col>
        <van-col span="6">上门时间</van-col>
      </van-row>
      <List v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-row v-for="item in lists" :key="item.id" @click="goDetail(item.id)" class="list-item">
          <van-col span="6">{{ item.house }}</van-col>
          <van-col span="12" class="item">
            <div v-if="item.sign_status.jj && item.sign_status.jj.length != 0">
              <span>家具（<span>{{ item.sign_status.jj.sign_num }} / {{ item.sign_status.jj.num }}</span>）：
                <span
                  :style="stageStyle(item.sign_status.jj.stage_str)">{{ item.sign_status.jj.stage_str }}</span></span>
            </div>
            <div v-if="item.sign_status.cl && item.sign_status.cl.length != 0">
              窗帘（<span>{{ item.sign_status.cl.sign_num }} / {{ item.sign_status.cl.num }}</span>）：
              <span
                :style="stageStyle(item.sign_status.cl.stage_str)">{{ item.sign_status.cl.stage_str }}</span>
            </div>
            <div v-if="item.sign_status.xyj && item.sign_status.xyj.length != 0">
              洗衣机（<span>{{ item.sign_status.xyj.sign_num }} / {{ item.sign_status.xyj.num }}</span>）：
              <span
                :style="stageStyle(item.sign_status.xyj.stage_str)">{{ item.sign_status.xyj.stage_str }}</span>
            </div>
          </van-col>
          <van-col span="6" style="text-align:center;">{{ item.last_time }}</van-col>
        </van-row>
      </List>
    </div>
  </div>
</template>
<script>
import { NavBar, List, Toast, } from 'vant';

export default {
  components: {
    NavBar, List
  },
  data () {
    return {
      lists: [],
      page: 1,
      loading: false,
      finished: false,
    }
  },
  mounted () {
    // this.onLoad()
  },
  methods: {
    onLoad () {
      var that = this
      this.axios.post('/api/investment/furniture_sign/list', { token: this.$store.state.TOKEN, page: this.page }).then(function (res) {
        if (res.data.code == 1) {
          if (res.data.data.current_page < res.data.data.last_page) {
            that.lists = that.lists.concat(res.data.data.data);
            that.page = that.page + 1;
            that.loading = false;
          } else {
            that.lists = that.lists.concat(res.data.data.data);
            that.finished = true;
          }
        } else {
          Toast.fail(res.data.msg);
        }
      }).catch(function () {
      });
    },
    goDetail (id) {
      this.$router.push('/detail?id=' + id)
    },
    stageStyle (e) {
      if (e === '验收完毕') {
        return {
          color: 'rgb(41 170 41)'
        }
      } else if (e === '待验收') {
        return {
          color: 'rgb(147 147 147)'
        }
      } else if (e === '部分验收') {
        return {
          color: 'rgb(248 114 3)'
        }
      } else if (e === '缺货') {
        return {
          color: '#db1212'
        }
      }
    }
  }

}
</script>
<style lang='scss' scoped>
.list-header {
  background: cornflowerblue;
  display: flex;
  color: #fff;
  line-height: 2rem;
  font-size: 0.8rem;
  padding: 0 0.6rem;
}

.all {
  color: red;
}

.little {
  color: green;
}
</style>
<style lang='scss'>
.list .van-nav-bar {
  background: cornflowerblue;

  .van-nav-bar__title {
    color: #fff;
  }
}

.van-list .list-item {
  border-bottom: 1px solid rgb(230, 228, 228);
  line-height: 1.6rem;
  font-size: 0.8rem;
  padding: 0.6rem 0.6rem;
  min-height: 2rem;
  text-align: left;

  .item {
    padding: 0 0.6rem 0 1.2rem;
  }
}</style>
